import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "./NavBar";
import SubmitApplication from "./SubmitApplication";


const Sales = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);

    const [token, setToken] = useState("")
    const [agentid, setAgentId] = useState("");
    const [podid, setPodId] = useState("");

    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setAgentId(session["custom:agentId"]);
          setPodId(session["custom:podId"]);
          console.log(session);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


    return (
      <div><NavBar />
      <SubmitApplication access={token} agentId={agentid} podId={podid}/></div>
      );
}

export default Sales;