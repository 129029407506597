import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { Modal, Button } from "react-bootstrap";

// Custom hook for creating table
const useCustomTable = (columns, data) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  };
};

const ProcessReminders = (props) => {
  const [reminderData, setReminderData] = useState([]);
  const [errorResult, setErrorResult] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [reminder, setReminder] = useState({agentId:props.agentId, text:"", duedate:""})


  useEffect(() => {
    fetchData();
    setReminder((prevReminder) => ({
      ...prevReminder,
      agentId: props.agentId
    }));
  }, [props.access, props.agentId]);


  const prepareReminder = (e) => {
    setReminder(prev => ({
      ...prev,
      [e.target.name] : e.target.value
    }));
  }


  const editReminder = (e) => {
    console.log(e);
  }


  const createReminder = async (e) => {
    e.preventDefault();
    try {
  
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/reminders/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-key": props.access,
        },
        body: JSON.stringify(reminder)
      });
      const responseJson = await response.json();
      if (response.ok) {
       console.log(responseJson.body);
       setShow(false);
        fetchData();
      } else {
        console.log(responseJson.body)
      }
    } catch (error) {
      console.error("Error completing reminders:", error);
    }
  }


  const completeReminder = async (e) => {
    try {
  
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/reminders/complete?reminderId=${e}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": props.access,
        },
      });
      const responseJson = await response.json();
      if (response.ok) {
       console.log(responseJson.body)
        fetchData();
      } else {
        console.log(responseJson.body)
      }
    } catch (error) {
      console.error("Error completing reminders:", error);
    }
  }


  const fetchData = async () => {
    try {
  
      const response = await fetch(`https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/reminders/fetch?agentId=${props.agentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": props.access,
        },
      });
      const responseJson = await response.json();
      if (response.ok) {
        setReminderData(responseJson.body);
        
      } else {
        console.error("Failed to load reminders");
        setErrorResult(responseJson.body);
      }
    } catch (error) {
      console.error("Error loading reminders:", error);
      setErrorResult("Error loading reminders");
    }
  };

  const reminderColumns = useMemo(
    () => [
      { Header: "Due Date", accessor: "duedate"},
      { Header: "Details", accessor: "text" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              className="btn m-0 p-1 reminder-complete"
              type="button"
              onClick={() => completeReminder(row.original.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-check-lg"
              >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022"></path>
              </svg>Mark As Complete
            </button>
            </>
        )
      },
    ],
    []
  );
  
  


  const {
    getTableProps: getReminderTableProps,
    getTableBodyProps: getReminderTableBodyProps,
    headerGroups: reminderHeaderGroups,
    rows: reminderRows,
    prepareRow: prepareReminderRow
  } = useCustomTable(reminderColumns, reminderData);

  return(
 <>
    <div className="container p-4">
        <div className="row">
            <div className="col col-10 border-bottom text-start">
                <div>
                    <h5>Your Reminders</h5>
                </div>
            </div>
            <div className="col col-2 border-bottom">
                <div><button className="btn p-0 m-0" type="button" onClick={handleShow}>&nbsp;Create Reminder</button></div>
            </div>
            <div className="col col-12 p-3">
                <div className="table-responsive">
                    <table className="table" {...getReminderTableProps()}>
                    <thead>
                    {reminderHeaderGroups.map((reminderHeaderGroup) => (
                        <tr {...reminderHeaderGroup.getHeaderGroupProps()}>
                        {reminderHeaderGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getReminderTableBodyProps()}>
                    {reminderRows.map((reminderRow) => {
                            prepareReminderRow(reminderRow);
                            return (
                              <tr {...reminderRow.getRowProps()}>
                              {reminderRow.cells.map((reminderCell, index) => (
                                <td
                                  {...reminderCell.getCellProps()}
                                  className={`text-start ${index === 0 || index === 2 ? "col-2" : index === 1 ? "col-8" : ""}`}
                                >
                                  {reminderCell.render("Cell")}
                                </td>
                              ))}
                            </tr>
                            
                            );
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <span>Reminder Time</span>
        <form><input className="form-control mb-3" type="datetime-local" name="duedate" onChange={prepareReminder}></input><label className="form-label mb-0">Reminder Details</label><textarea className="form-control" name="text" onChange={prepareReminder}></textarea></form>
        <div className="w-100 text-end p-3"><button className="btn btn-light" type="button" onClick={handleClose}>Cancel</button><button className="btn btn-primary ms-4" type="button" onClick={createReminder}>Add Reminder</button></div>
        </Modal.Body>
      </Modal>
        </div>
    </div>
    </>
  )
};

export default ProcessReminders;
