import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "./NavBar";
import LoadLeaderboards from "./LoadLeaderboards";


const Leaderboards = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const [token, setToken] = useState("")
    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


      return (
        <div>
          <NavBar />
          {token ? (
            <LoadLeaderboards access={token} />
          ) : (
            <div>
              <h1 className="p-5">Loading....</h1>
            </div>
          )}
        </div>
      );
      
}

export default Leaderboards;