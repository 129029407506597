import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginerror, setLoginError] = useState("");
  const navigate = useNavigate();
  const { authenticate, getSession } = useContext(AccountContext);


  useEffect(() => {
    getSession().then((session) => {
      navigate('/sales');
    }).catch((err) =>{
      navigate('/auth');
    })
    ;
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setLoginError("");
    authenticate(email, password)
      .then((data) => {
        if(data.idToken.jwtToken){
          navigate('/sales');
        }
      })
      .catch((err) => {
        setLoginError(err.message);
      });
  };

  return (
    <section className="position-relative py-4 py-xl-5">
        <div className="container">
            <div className="row mb-5">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                    <h2>Welcome Back! Please Log In.</h2>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col col-12 col-md-6">
                    <div className="card mb-5">
                        <div className="card-body d-flex flex-column align-items-center">
                            <div className="p-3"><img src="/img/tappolicyheadergraphic-cropped.png"></img></div>
                            <form className="text-center" onSubmit={onSubmit}>
                                <div className="mb-3"><input className="form-control login-input" type="email" name="email" placeholder="Email"  value={email} onChange={(event) => setEmail(event.target.value)} required></input></div>
                                <div className="mb-3"><input className="form-control" type="password" name="password" placeholder="Password"  value={password} onChange={(event) => setPassword(event.target.value)} required minLength={8}></input></div>
                                <div className="mb-3"><button className="btn btn-primary d-block w-100" type="submit">Login</button></div>
                                <span className="mt-2">{loginerror}</span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Login;
