import React, {useState, useContext, useEffect} from "react";
import { AccountContext } from "./Account";


const SubmitApplication = (props) => {

    const [confirmationid, setconfirmationid] = useState("");
    const [planid, setplanid] = useState("")
    const [effectivedate, seteffectivedate] = useState("");
    const [showeffectivedate, setshoweffectivedate] = useState(false);
    const [showcarrierplanid, setshowcarrierplanid] = useState(true)
    const [category, setcategory] = useState("");
    const [carrier, setcarrier] = useState("");
    const [podid, setpodid] = useState("");
    const [agentid, setagentid] = useState("");
    const [submitting, setSubmission] = useState(false);
    const [submissionResult, setSubmissionResult] = useState("");
    const [callerMBID, setCallerMBID] = useState("");
    const [hracomplete, sethracomplete] = useState(false);
    const [vbccomplete, setvbccomplete] = useState("No");


    useEffect(() => {
      setpodid(props.podId);
      setagentid(props.agentId);
    }, [props.podId, props.agentId]);

    useEffect(() =>{
      if(category === "data" || category === "transfer" || category === "hra"){
        setshoweffectivedate(false);
        setshowcarrierplanid(false);
      }else if(category==="medadv" || category==="mapd"|| category==="pdp"){
        setconfirmationid("");
        setshoweffectivedate(true);
        setshowcarrierplanid(true);
      }
      
    }, [category])
    

  const resetForm = () => {
    setconfirmationid("");
    setcategory("");
    setSubmissionResult("");
    setSubmission(false);
  }
  



  const handleSubmit = async (e) => {
    e.preventDefault();
      setSubmission(true);
      setSubmissionResult("Submitting, please wait...");
      let formData = {
          "confirmid" : confirmationid,
          "category" : category,
          "podid" : podid,
          "agentid" : agentid,
          "effectivedate" :effectivedate,
          "mbid":callerMBID,
          "carrier":carrier,
          "planid":planid,
          "hra":hracomplete,
          "vbc":vbccomplete,
          "orgid":"59407365-f696-4ab5-b52f-200402a52d1a"
      }
      try {
        const response = await fetch("https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/recordapp", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-key":props.access
            },
            body: JSON.stringify(formData),
          });
          const responseJson = await response.json();
          if (response.ok) {
            setSubmissionResult(responseJson.body);
          } else {
            console.error("Failed to submit application");
            setSubmissionResult(responseJson.body);
          
          }
    } catch (error) {
        console.error("Error submitting application:", error);
        setSubmissionResult("Error submitting application");
      
    }


  };

  const handlevbc = (e) =>{
    if(e.target.checked){
      setvbccomplete(e.target.name)
    }else{
      setvbccomplete("No")
    }
  }

  useEffect(() => {
    if (submitting) {
      const timeoutId = setTimeout(() => {
        resetForm();
      }, 4000); 
      return () => clearTimeout(timeoutId);
    }
  }, [submitting]);



  return (
    <div className="container p-4">
      <div className="row">
        <div className="col">
          <h2 className="text-center">Application Submission Form</h2>
          <form className="submission-form" onSubmit={handleSubmit}>
          <label className="w-100 text-start p-2">Application Type</label>
            <select className="form-select mb-3" value={category} onChange={(e) => { setcategory(e.target.value) }} required>
              <option value="" disabled>Select an Application Category</option>
              <option value="mapd">(MAPD) Medicare Advantage With Prescription Drug Plan</option>
              <option value="medadv">(MA) Medicare Advantage</option>
              <option value="pdp">(PDP) Standalone Prescription Drug Plan</option>
              <option value="data">(VBC) Data</option>
              <option value="transfer">(VBC) Transfer</option>
              <option value="hra">(HRA) Health Risk Assessment</option>
            </select>
            {showeffectivedate && (
            <>
            <label className="w-100 text-start p-2">Effective Date</label>
            <input className="form-control mb-3" type="date" value={effectivedate} onChange={(e)=>{seteffectivedate(e.target.value)}} required min="2024-01-01" max="2024-12-31"></input>

            <label className="w-100 text-start p-2">Carrier Name</label>
            <select className="form-select mb-3" value={carrier} onChange={(e) => { setcarrier(e.target.value) }} required>
              <option value="" disabled>Select Carrier Name</option>
              <option value="UHC">United Healthcare</option>
              <option value="Aetna">Aetna</option>
              <option value="Cigna">Cigna</option>
              <option value="Elevance">Anthem/Elevance</option>
              <option value="WellCare">WellCare</option>
              <option value="Humana">Humana</option>
            </select>
            <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-20" checked={hracomplete} name="hraaddition" onChange={(e) => {sethracomplete(e.target.checked)}} />
                          <label className="form-check-label" htmlFor="formCheck-20">&nbsp;HRA Completed</label>
                        </div>
            <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-21" checked={vbccomplete === "data" ? true : false} name="data" onChange={(e) => {handlevbc(e)}} />
                          <label className="form-check-label" htmlFor="formCheck-21">&nbsp;VBC Completed - Data</label>
                        </div>
            <div className="form-check d-inline-flex me-3 mb-3">
                          <input className="form-check-input" type="checkbox" id="formCheck-22" checked={vbccomplete === "transfer" ? true : false} name="transfer" onChange={(e) => {handlevbc(e)}} />
                          <label className="form-check-label" htmlFor="formCheck-22">&nbsp;VBC Completed - Transfer</label>
                        </div>
            </>
          )}
            <label className="w-100 text-start p-2">Confirmation ID</label>
            <input className="form-control mb-3" type="text" placeholder="ex #12345" value={confirmationid} onChange={(e)=>{setconfirmationid(e.target.value)}} required/>
            {
              showcarrierplanid && (
                <>
                            <label className="w-100 text-start p-2">Carrier Plan ID</label>
                            <input className="form-control mb-3" type="text" placeholder="ex A0000-000-000" value={planid} onChange={(e)=>{setplanid(e.target.value)}} required/>
                </>
              )
            }
            <label className="w-100 text-start p-2">Your Pod ID</label>
            <input className="form-control mb-3" type="text" placeholder="ex p-0123456" value={podid} required readOnly/>
            <label className="w-100 text-start p-2">Your Agent ID</label>
            <input className="form-control mb-3" type="text" placeholder="ex 54321" value={agentid} required readOnly/>
            <label className="w-100 text-start p-2">Customer MBID</label>
            <input className="form-control mb-3" type="text" placeholder="Enter Customer MBID" value={callerMBID} required minLength="11" maxLength="11"  onChange={(e) => {setCallerMBID(e.target.value)}}/>
            <div className="col-12 text-center">
              <button className="btn btn-primary" type="submit">
                Record Application
              </button>
              <p className={`mt-2 ${submitting ? '' : 'hide'}`}>{submissionResult}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubmitApplication;
