import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';

const NavBar = () =>{
    const { logout } = useContext(AccountContext);
    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-md bg-body py-3 navbar-boxshadow">
        <div className="container"><a className="navbar-brand d-flex align-items-center" href="#"><img className="db-logo" src="/img/tappolicyheadergraphic-cropped.png"></img></a><button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-2"><span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navcol-2">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item"><a className="nav-link" onClick={(e)=> { navigate("/sales")}}>Record Sale</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={(e)=> { navigate("/leaderboard")}}>Leaderboards</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={(e)=> { navigate("/reminders")}}>Reminders</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={(e)=> {logout(); navigate("/auth")}}>Logout</a></li>
                </ul>
            </div>
        </div>
    </nav>
    )
}

export default NavBar

