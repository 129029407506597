import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";

// Custom hook for creating table
const useCustomTable = (columns, data) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy);

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  };
};

const LoadLeaderboards = (props) => {
  const [agentData, setAgentData] = useState([]);
  const [podData, setPodData] = useState([]);
  const [errorResult, setErrorResult] = useState("");

  

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Perform your desired action here based on agentData or podData changes
    // Example: yourActionFunction(agentData, podData);
  }, [agentData, podData]);

  const fetchData = async () => {
    try {
  
      const response = await fetch("https://rhj6vso046.execute-api.us-east-1.amazonaws.com/prod/leaderboarddata", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-key": props.access,
        },
      });
      const responseJson = await response.json();
      if (response.ok) {
        setAgentData(responseJson.body.agents);
        setPodData(responseJson.body.pods);
      } else {
        console.error("Failed to load leaderboard");
        setErrorResult(responseJson.body);
      }
    } catch (error) {
      console.error("Error loading leaderboard:", error);
      setErrorResult("Error loading leaderboard");
    }
  };

  const podColumns = useMemo(
    () => [
      { Header: "Pod Name", accessor: "podname" },
      { Header: "Day", accessor: "appstoday" },
      { Header: "Week", accessor: "appsweek" },
      { Header: "Month", accessor: "appsmonth" },
      { Header: "YTD", accessor: "appsyear" }
    ],
    []
  );

  const agentColumns = useMemo(
    () => [
      { Header: "Agent Name", accessor: "name" },
      { Header: "Day", accessor: "appstoday" },
      { Header: "Week", accessor: "appsweek" },
      { Header: "Month", accessor: "appsmonth" },
      { Header: "YTD", accessor: "appsyear" }
    ],
    []
  );

  const {
    getTableProps: getPodTableProps,
    getTableBodyProps: getPodTableBodyProps,
    headerGroups: podHeaderGroups,
    rows: podRows,
    prepareRow: preparePodRow
  } = useCustomTable(podColumns, podData);

  const {
    getTableProps: getAgentTableProps,
    getTableBodyProps: getAgentTableBodyProps,
    headerGroups: agentHeaderGroups,
    rows: agentRows,
    prepareRow: prepareAgentRow
  } = useCustomTable(agentColumns, agentData);

  return(
 
    <div className="container mt-4">
    <div className="row p-3 mb-5 scroll-stop">
        <div className="col">
            <h2 className="text-center">Application Leaderboard (By Pod)</h2>
            <div className="table-responsive">
                <table className="table table-bordered" {...getPodTableProps()}>
                    <thead>
                    {podHeaderGroups.map((podHeaderGroup) => (
                        <tr {...podHeaderGroup.getHeaderGroupProps()}>
                        {podHeaderGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getPodTableBodyProps()}>
                    {podRows.map((podRow) => {
                            preparePodRow(podRow);
                            return (
                            <tr {...podRow.getRowProps()}>
                                {podRow.cells.map((podCell) => (
                                <td {...podCell.getCellProps()}>{podCell.render("Cell")}</td>
                                ))}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div className="row p-3 mb-5 scroll-stop">
        <div className="col">
            <h2 className="text-center">Application Leaderboard (By Agent)</h2>
            <div className="table-responsive">
                <table className="table table-bordered" {...getAgentTableProps()}>
                    <thead>
                    {agentHeaderGroups.map((agentHeaderGroup) => (
                        <tr {...agentHeaderGroup.getHeaderGroupProps()}>
                        {agentHeaderGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getAgentTableBodyProps()}>
                    {agentRows.map((agentRow) => {
                            prepareAgentRow(agentRow);
                            return (
                            <tr {...agentRow.getRowProps()}>
                                {agentRow.cells.map((agentCell) => (
                                <td {...agentCell.getCellProps()}>{agentCell.render("Cell")}</td>
                                ))}
                            </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

  )
};

export default LoadLeaderboards;
