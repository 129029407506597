import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { useNavigate } from 'react-router-dom';
import NavBar from "./NavBar";
import ProcessReminders from "./ProcessReminders";


const Reminders = () =>{
    const navigate = useNavigate();
    const { getSession } = useContext(AccountContext);
    const [token, setToken] = useState("");
    const [agentid, setagentid] = useState("");
    useEffect(() => {
        getSession().then((session) => {
          setToken(session.idToken.jwtToken);
          setagentid(session["custom:agentId"]);
        }).catch((err) =>{
          navigate('/auth');
        })
        ;
      }, []);


      return (
        <div>
          <NavBar />
          {token ? (
            <ProcessReminders access={token} agentId={agentid} />
          ) : (
            <div>
              <h1 className="p-5">Loading....</h1>
            </div>
          )}
        </div>
      );
      
}

export default Reminders;