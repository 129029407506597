import React from "react";
import "./App.css";
import Login from "./components/Login";
import { Account } from "./components/Account";
import Sales from "./components/Sales";
import Leaderboards from "./components/Leaderboard";
import Reminders from "./components/Reminders";
import ErrorPage from "./components/ErrorPage";
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

const App = () => {
  return (
    <div className="App">
        <Account>
      <Router>
        <Routes>
          <Route path="/auth" element={<Login />}/>
          <Route path="/sales" element={<Sales />}/>
          <Route path="/leaderboard" element={<Leaderboards />}/>
          <Route path="/reminders" element={<Reminders />}/>
          <Route path="/" element={<Navigate to="/auth" />} />
          <Route path="*" element={<ErrorPage />}/>
        </Routes>
      </Router>
      </Account>
    </div>
  );
};

export default App;
